/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import AlittechLogo from "../Images/alittech.png";
import { useLocation, useNavigate } from "react-router";

const Footer = (props) => {
  const CurrentPageLocation = useLocation();
  const navigateTo = useNavigate();

  const NavigateToHome = () => {
    if (CurrentPageLocation.pathname !== "/") {
      navigateTo("/");
    }
  };

  const onClickTermsAndCondtion = () => {
    props.setActiveSection("");
    navigateTo("/TermsAndConditions");
    window.scrollTo({top: 0, left: 0, behavior: "instant" });
  };

  return (
    <>
      <div>
        <footer className="">
          <div className="max-w-screen-xl p-4 py-6 mx-auto lg:py-16 md:p-8 lg:p-10">
            <div className="row py-3">
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4   d-flex items-center">
                <div>
                  <a
                    href="/"
                    className="flex items-center justify-center mb-4 text-2xl font-semibold text-gray-900 "
                  >
                    <img
                      src="MarkerLiteLogo.png"
                      width={200}
                      alt="Marker Lite Logo"
                    />
                  </a>
                  <ul className="flex space-x-5 justify-center pb-4">
                    <li>
                      <a
                        href="https://www.youtube.com/@MarkerLite"
                        rel="noreferrer"
                        target="_blank"
                        className="text-gray-500 hover:text-gray-900"
                        title="linkedin"
                      >
                        <svg
                          fill="currentColor"
                          className="w-6 h-6"
                          version="1.1"
                          id="Layer_1"
                          viewBox="0 0 461.001 461.001"
                        >
                          <path d="M365.257,67.393H95.744C42.866,67.393,0,110.259,0,163.137v134.728 c0,52.878,42.866,95.744,95.744,95.744h269.513c52.878,0,95.744-42.866,95.744-95.744V163.137 C461.001,110.259,418.135,67.393,365.257,67.393z M300.506,237.056l-126.06,60.123c-3.359,1.602-7.239-0.847-7.239-4.568V168.607 c0-3.774,3.982-6.22,7.348-4.514l126.06,63.881C304.363,229.873,304.298,235.248,300.506,237.056z" />
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/people/Marker-Lite/61552679609841/?mibextid=ZbWKwL"
                        rel="noreferrer"
                        target="_blank"
                        className="text-gray-500 hover:text-gray-900 "
                        title="Facebook"
                      >
                        <svg
                          className="w-6 h-6"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/marker.lite/?igshid=MzMyNGUyNmU2YQ%3D%3D"
                        rel="noreferrer"
                        target="_blank"
                        title="instagram"
                        className="text-gray-500 hover:text-gray-900 "
                      >
                        <svg
                          className="w-6 h-6"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <path
                            fillRule="evenodd"
                            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://twitter.com/InfoMakerlite"
                        className="text-gray-500 hover:text-gray-900"
                      >
                        <svg
                          className="w-6 h-6"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.whatsapp.com/channel/0029VaDRfXp05MUYUrVPZn0A"
                        rel="noreferrer"
                        target="_blank"
                        className="text-gray-500 hover:text-gray-900"
                        title="whatsapp"
                      >
                        <svg
                          className="w-6 h-6"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <path d="M16.6 14c-.2-.1-1.5-.7-1.7-.8-.2-.1-.4-.1-.6.1-.2.2-.6.8-.8 1-.1.2-.3.2-.5.1-.7-.3-1.4-.7-2-1.2-.5-.5-1-1.1-1.4-1.7-.1-.2 0-.4.1-.5.1-.1.2-.3.4-.4.1-.1.2-.3.2-.4.1-.1.1-.3 0-.4-.1-.1-.6-1.3-.8-1.8-.1-.7-.3-.7-.5-.7h-.5c-.2 0-.5.2-.6.3-.6.6-.9 1.3-.9 2.1.1.9.4 1.8 1 2.6 1.1 1.6 2.5 2.9 4.2 3.7.5.2.9.4 1.4.5.5.2 1 .2 1.6.1.7-.1 1.3-.6 1.7-1.2.2-.4.2-.8.1-1.2l-.4-.2m2.5-9.1C15.2 1 8.9 1 5 4.9c-3.2 3.2-3.8 8.1-1.6 12L2 22l5.3-1.4c1.5.8 3.1 1.2 4.7 1.2 5.5 0 9.9-4.4 9.9-9.9.1-2.6-1-5.1-2.8-7m-2.7 14c-1.3.8-2.8 1.3-4.4 1.3-1.5 0-2.9-.4-4.2-1.1l-.3-.2-3.1.8.8-3-.2-.3c-2.4-4-1.2-9 2.7-11.5S16.6 3.7 19 7.5c2.4 3.9 1.3 9-2.6 11.4"></path>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/in/marker-lite-768b23298"
                        rel="noreferrer"
                        target="_blank"
                        className="text-gray-500 hover:text-gray-900"
                        title="linkedin"
                      >
                        <svg
                          className="w-6 h-6"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          aria-hidden="true"
                        >
                          <path d="M20.47,2H3.53A1.45,1.45,0,0,0,2.06,3.43V20.57A1.45,1.45,0,0,0,3.53,22H20.47a1.45,1.45,0,0,0,1.47-1.43V3.43A1.45,1.45,0,0,0,20.47,2ZM8.09,18.74h-3v-9h3ZM6.59,8.48h0a1.56,1.56,0,1,1,0-3.12,1.57,1.57,0,1,1,0,3.12ZM18.91,18.74h-3V13.91c0-1.21-.43-2-1.52-2A1.65,1.65,0,0,0,12.85,13a2,2,0,0,0-.1.73v5h-3s0-8.18,0-9h3V11A3,3,0,0,1,15.46,9.5c2,0,3.45,1.29,3.45,4.06Z"></path>
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 pt-2">
                <ul className="text-gray-500 ">
                  <li className="mb-4">
                    <a href="#" className="hover:underline">
                      Home
                    </a>
                  </li>
                  <li className="mb-4">
                    <a
                      href="#feature-section"
                      onClick={NavigateToHome}
                      className="hover:underline"
                    >
                      Features
                    </a>
                  </li>
                  <li className="mb-4">
                    <a
                      href="#benefit-section"
                      onClick={NavigateToHome}
                      className="hover:underline"
                    >
                      Benefits
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 pt-2">
                <ul className="text-gray-500 ">
                  <li className="mb-4">
                    <a
                      href="#ourmission-section"
                      onClick={NavigateToHome}
                      className="hover:underline"
                    >
                      Our Mission
                    </a>
                  </li>
                  <li className="mb-4">
                    <a
                      href="#pricing-section"
                      onClick={NavigateToHome}
                      className="hover:underline"
                    >
                      Pricing
                    </a>
                  </li>
                  <li className="mb-4">
                    <a
                      href="#contactus-section"
                      onClick={NavigateToHome}
                      className="hover:underline"
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 pt-2 ">
                <ul className="text-gray-500 ">
                  <li className="mb-4">
                    <a
                      href="#faq-section"
                      onClick={NavigateToHome}
                      className="hover:underline"
                    >
                      FAQs
                    </a>
                  </li>
                  <li className="mb-4">
                    <button
                      onClick={onClickTermsAndCondtion}
                      className="hover:underline"
                    >
                      Terms & Conditions
                    </button>
                  </li>
                </ul>
              </div>
              <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 d-flex items-center justify-content-xxl-end justify-content-xl-end justify-content-lg-end mb-4">
                <a
                  href="https://www.alittech.com/"
                  rel="noreferrer"
                  target="_blank"
                  className="hover:underline"
                >
                  <div className="ps-5 ms-3 text-gray-500">Developed By</div>
                  <img src={AlittechLogo} alt="logo" width={200} />
                </a>
              </div>
            </div>

            <hr className="my-8 border-gray-300 lg:my-8 " />
            <div className="text-center py-2">
              <span className="block text-sm text-center text-gray-500 ">
                Copyright © 2023-2024{" "}
                <a href="/" className="text-blue-600 hover:underline ">
                  Marker Lite
                </a>
                . All Rights Reserved{" "}
                <a
                  href="https://www.alittech.com/"
                  rel="noreferrer"
                  target="_blank"
                  className="text-blue-600 hover:underline "
                >
                  Alit Technologies
                </a>
                .
              </span>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
