import React, { useEffect, useState } from "react";
import { baseUrl } from "../base-url";
import thumb from "../Images/thumb-01.png";
import { detect } from "detect-browser";

const browserInfo = detect();

const DownloadUrl = `${baseUrl.OPEN_API_URL}/DownloadLatestVersion/1`;

const DownloadLogsUrl = `${baseUrl.OPEN_API_URL}/InsertProductDownloadLog`;

const DownloadPage = () => {
  const StartDownload = () => {
    var a = document.createElement("a");
    a.href = DownloadUrl;
    a.download = "download";
    a.hidden = true;
    a.click();
    GetIpAddress();
  };

  const GetIpAddress = async () => {
    // location & ip address
    setTimeout(async () => {
      await fetch("https://api.ipify.org/?format=json")
      .then((res) => res.json())
      .then((ip) => {
        if (ip) {
          GetLocationDetails(ip.ip);
        } else {
          GetLocationDetails(null);
        }
      });
    }, 500);
  
  };

  const GetLocationDetails = async (ip) => {

    //  Get location details
    var Userlocation = null;
    await fetch(`https://freeipapi.com/api/json/${ip}`)
      .then((response) => response.json())
      .then((data) => {
        if (data) {

          Userlocation = `${data.cityName}, ${data.regionName}, ${data.countryName}, ${data.zipCode}`;
          InsertDownloadLog(ip, Userlocation);
        } else {
          InsertDownloadLog(null, null);
        }

      })

  };

  const InsertDownloadLog = async (ipAddress, Userlocation) => {
    var userAgent = navigator.userAgent;
    
    // browser name & OS
    var browserName = null;
    var OSName = null;
    if (browserInfo) {
      browserName = browserInfo.name;
      if (browserInfo.os === "Windows 10") {
        OSName = "Windows 10/11";
      } else {
        OSName = browserInfo.os;
      }
    }

    // screen size
    var screenWidth = window.innerWidth;
    var screenHeight = window.innerHeight;
    var screenSize = `${screenWidth} * ${screenHeight}`;

    //  device type
    var deviceType = null;
    if (
      /Mobile|Android|iPhone|iPad|iPod|Windows Phone|BlackBerry|Kindle|Silk|Opera Mini|Opera Mobi/i.test(
        userAgent
      )
    ) {
      deviceType = "mobile";
    } else if (/Tablet|iPad/i.test(userAgent)) {
      deviceType = "tablet";
    } else {
      deviceType = "desktop";
    }
    
    var productID = 1;

    const downloadLogs = {
      productID: productID,
      os: OSName,
      browserName: browserName,
      screenSize: screenSize,
      language: navigator.language,
      deviceType: deviceType,
      ipAddress: ipAddress,
      location: Userlocation,
      emailID: null,
      mobileNo: null,
    };
    
  
      const requestOptions = {
        method: "POST",
        headers: {
          'Accept': "*/*",
          'Content-Type': "application/json"        
        },
        body: JSON.stringify(downloadLogs)
      }
  
      await fetch(DownloadLogsUrl, requestOptions);
    
  
  };

  const [renderVideo, setRenderVideo] = useState(false);

  useEffect(() => {
    StartDownload();
    setTimeout(() => {
      setRenderVideo(true);
    }, 500);
  }, []);

  return (
    <>
      <div className=" d-flex pt-5 justify-content-center download-page">
        <div className="text-center pt-2">
          <div className="d-flex justify-center pb-4 pt-5">
            <img src={thumb} alt="thankyou" />
          </div>
          <h3 className="font-bold text-2xl text-blue-600">
            Thank you for downloading Marker Lite
          </h3>

          <p className="pt-2 text-gray-500">
            Your download will start in few seconds...
          </p>
          <p className="text-gray-500">
            if not,{" "}
            <button
              onClick={StartDownload}
              className="font-medium text-blue-600 hover:text-blue-800 hover:underline"
            >
              click here
            </button>
          </p>
        </div>
      </div>

      {/* video */}
      
        <>
          <section className="d-flex justify-content-center pb-5 bg-gray-50 pt-6 border-bottom">
            <div className="max-w-screen-md mx-auto py-4">
              <h2 className="mb-6 text-3xl font-extrabold tracking-tight text-center text-gray-900 lg:mb-8 lg:text-3xl ">
                How to use Marker Lite
              </h2>
              {renderVideo && (
              <iframe
                className="video-configure"
                src="https://www.youtube.com/embed/BlgYSgZdPTg?si=IWoVZ-JnY43njekv&amp;start=94"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              )}
            </div>
          </section>
        </>
      
    </>
  );
};

export default DownloadPage;
