import React from "react";

const TermsAndConditions = () => {
  return (
    <>
      <section id="terms-conditions" className="bg-white pt-5 border-bottom ">
        <div className="max-w-screen-xl px-4 pb-8 mx-auto lg:pb-16 ">
          <h2 className="mb-4 pt-5 mt-4 text-3xl font-extrabold tracking-tight text-gray-900 md:text-3xl border-bottom pb-2">
            Marker Lite Terms & Conditions and Privacy Policy
          </h2>
          The terms used in the Privacy Policy ("Policy") have the meaning as
          defined in the Terms of Use and are to be read in conjunction with the
          Terms of Use. This policy outlines how Alit Technologies ("the
          Company") collects, uses, discloses, shares, and transfers user
          information. This Policy applies to anyone who visits Marker Lite
          website or is registered on the Marker Lite software.
          <br />
          <h3 className="mb-3 pt-5 text-2xl font-semibold  text-gray-900  border-bottom pb-2">
            Introduction
          </h3>
          Alit Technologies ("the Company") recognizes the need for appropriate
          protection and management of information shared with the Company or on
          the Marker Lite software.
          <br />
          <br />
          By using or accessing Marker Lite in any manner or registering, you
          acknowledge acceptance of this Policy and consent to the Company for
          collection, use, disclosure, sharing, and transfer of information and
          personal data, including sensitive Personal Information. This Policy
          acknowledges all software operations approved by the registered person
          and their liability and responsibility for data accuracy, forming an
          electronic contract under the Information Technology Act, 2000, and
          the rules made thereunder.
          <br />
          <br /> However, if a user does not wish to share certain information
          as stated in this Policy, they are free to opt out or refrain from
          using Marker Lite and/or the Products and Services by deleting their
          registration. In such cases, they are not required to give consent as
          per this Privacy Policy.
          <br />
          <br /> If, in the future, it is determined that certain information
          should not be used or should be deleted, consent granted in this
          Policy can be withdrawn by writing to the Company to withdraw the
          granted consent or by deleting the registration. However, if consent
          for the Company to use the information is withdrawn, the Products and
          Services cannot be offered to the user by the Company.
          <br />
          <h2 className="mb-3 pt-5 text-2xl font-semibold  text-gray-900 border-bottom pb-2">
            Modification of privacy policy
          </h2>
          These policies may be updated from time to time in the future. Users
          should regularly review our privacy policies by visiting this page.
          Continued access to or use of the service implies agreement to any
          changes.
          <br />
          <br />
          <h2 className="mb-3 pt-5 text-2xl font-semibold  text-gray-900 border-bottom pb-2">
            When this privacy statement applies
          </h2>
          This Privacy Policy applies to those who have downloaded Marker Lite
          and registered by clicking on the "I Agree/Login" button for Terms and
          Conditions or have explicitly consented to the policy on any other
          product/service page offered to the User.
          <br />
          <h2 className="mb-3 pt-5 text-2xl font-semibold  text-gray-900 border-bottom pb-2">
            How Information is Collected, Used, Disclosed, Shared, and Stored
          </h2>
          1. The Company collects, uses, discloses, shares, and transfers
          Information, including but not limited to User Content, when Users
          access or use Marker Lite for the Products and Services. By using
          Marker Lite and/or Products and Services, you grant express consent
          and permission for the Company to access, use, process, disclose,
          share, and transfer information in accordance with this Policy and the
          Terms of Use.
          <br />
          <br /> 2. The term "Personal Information" means any information
          provided by the User and includes User content, documents, details,
          and information shared with the Company, as well as the One Time
          Password (OTP) received from government authorities for
          authenticating/verifying the goods and service tax registration
          process, as described in the Terms of Use.
          <br />
          <br /> 3. the term "Non-Personal Information" refers to information
          that does not specifically identify an individual but includes
          information such as the type of device, unique ID, Internet Service
          Provider (ISP), Internet Protocol (IP) address, etc.
          <br />
          <br /> 4. Personal and Non-Personal Information together is referred
          to as “Information”. The Company may collect Information when
          registration happens on the Marker Lite, when the features or the
          Products and Services are used, when the User shares the User content
          with the Company, and when the User interacts with the customer care
          team including the details of the email message or chats etc.
          <br />
          <br /> 5. The Company may use cookies to monitor usage including,
          without limitation, to provide useful features to simplify experiences
          for the User on the Marker Lite, like remembering login ID and certain
          Personal Information, and to deliver relevant content based on
          preferences, usage patterns, and location.
          <br />
          <br /> 6. There could also arise a requirement to provide a
          credit/debit card when the User is required to pay Government Fees
          and/or Facilitation Fees as per the Terms of Use. The Company or any
          third party providing services related to payment may use this
          information, such as account and authentication information and
          billing, delivery, and contact details. The Company shall in no case
          be liable for any loss or damage sustained by the User arising out of
          any disclosure (inadvertent or otherwise) of any information relating
          to bank account, credit, or debit card details in the course of any
          online transactions or payments made relating to Government Fees
          and/or Facilitation Fees.
          <br />
          <br />
          7. The Company collects information about the User’s use of the Marker
          Lite and/or Products and Service, such as the features used, any
          activity carried out, the actions that are taken, and the time,
          frequency, and duration of the said activities.
          <br />
          <br />
          8. The Company is required to collect information from and about the
          computers, Laptops, and other connected devices used and the Company
          then combines this information across different devices that are used.
          <br />
          <h2 className="mb-3 pt-5 text-2xl font-semibold  text-gray-900 border-bottom pb-2">
            Information that the Company obtains from these devices includes:
          </h2>
          a. Information including but not limited to the operating system,
          hardware and software versions, battery level, available storage
          space, software file names and types, and plugins, name of the
          operator, language, time zone, IP address, connection speed and, in
          some cases, information about other devices that are nearby or on user
          network, etc.
          <br />
          <br />
          b. Location-related information – such as current location to provide,
          personalized and improved use of the Marker Lite and/or Products and
          Services. Location-related information can be based on things such as
          precise device location, IP addresses, etc.
          <br />
          <h2 className="mb-3 pt-5 text-2xl font-semibold  text-gray-900 border-bottom pb-2">
            USER RESPONSIBILITIES
          </h2>
          1. The Company does not accept any responsibility or liability for any
          loss or damage the User may suffer or incur if any information,
          documentation, material, or data provided to avail the Services is
          incorrect, incomplete, inaccurate, or misleading, or if the User fails
          to disclose any material fact.
          <br />
          <br />
          2. User is solely responsible for the data backup and safekeeping.
          Company tries keeping Users data safe but holds zero responsibility in
          case of data leaks.
          <br />
          <br />
          3. The User shall be solely responsible for ensuring compliance with
          applicable laws and shall be solely liable for any liability that may
          arise due to a breach of its obligations in this regard.
          <br />
          <br />
          4. While the Company uses commercially reasonable efforts to provide
          Users with a daily backup of their Transaction Information, Users
          should regularly and independently save, backup, and archive such
          Transaction Information.
          <br />
          <br />
          5. The User shall be solely responsible for compliance with all the
          applicable laws including without limitation the Prevention of Money
          Laundering Act, 2002, and the rules made thereunder. The Company shall
          not be responsible for any claims liability or losses that may arise
          due to non-compliance with the anti-money laundering laws in India.
          <br />
          <br />
          6. Users are solely responsible for maintaining the confidentiality of
          their username and password. Sharing these details is not advised, and
          the Company is not liable for breaches of this covenant.
          <br />
          <h2 className="mb-3 pt-5 text-2xl font-semibold  text-gray-900 border-bottom pb-2">
            How Marker Lite stores and shares google user data
          </h2>
          Marker Lite does not store any Google user data. Whenever Marker Lite
          fetches this data, it gets shown to the user while using the feature.
          The user chooses the data he wants to use for import and that gets
          used to create customers/vendors for him in the software. Marker Lite
          will not store this data for any further use. Marker Lite will also
          not share the user's Google authentication data with anyone.
          <br />
          <br /> Collection of installed applications <br />
          <br />
          We collect a list of the installed applications’ metadata information
          which includes the application name, package name, installed time,
          updated time, version name, and registered email of each installed
          application on your device. <br />
          <br />
          Collection of other non-personal information <br />
          <br />
          We automatically track
          certain information about you based upon your behavior on our
          Platform. We use this information to do internal research on our
          users’ demographics, interests, and behavior to better understand,
          protect, and serve our users and improve our services. This
          information is compiled and analyzed on an aggregated basis. Use of
          this information helps Us identify You in order to make it more
          user-friendly. we provide customer support and troubleshoot problems
          as permitted by law. if you send us personal correspondence, such as
          emails, or if other users or third parties send us correspondence
          about your activities, we collect such information into a file
          specific to you.<br/>
          <h2 className="mb-3 pt-5 text-2xl font-semibold  text-gray-900 border-bottom pb-2">
          How Marker Lite uses user data
          </h2>
            The Company may use
          the Information for:<br/><br/> 1. Providing access to the Marker Lite and/or use
          of the Products and Services.<br/><br/> 2. Registration with the Company.<br/><br/> 3.
          Send email for authenticating via One Time Password (OTP) etc.<br/><br/>4.
          Conduct a general survey or analysis of Accounts or otherwise.<br/><br/>5.
          Develop, test, and improve the Marker Lite and/or Products and
          Services, testing and troubleshooting new products and features,
          removing any glitches including technical problems, improving the
          experience of navigating and carrying out transactions, and improving
          the services and content, Products and Service on the Marker Lite.<br/>

          <h2 className="mb-3 pt-5 text-2xl font-semibold  text-gray-900 border-bottom pb-2">
          Sharing of personal information
          </h2>

           The Company may disclose or share
          Personal Information including but not limited to User Content in the
          following ways:<br/><br/>1. Disclosure or sharing with government authorities
          including but not limited to the Goods and Service Tax Authority, MSME
          Authority, FSSAI Authority. Information including Personal Information
          may be shared with and disclosed to government authorities<br/><br/>2.
          Disclosure to Government authorities or as required by law information
          may be shared with Government and/or statutory authorities or under
          court orders or other legal processes; to establish or exercise legal
          rights; to defend against legal claims; or as otherwise required by
          law. The Company warrants that such information will be disclosed only
          in accordance with applicable directions, instructions, laws and
          regulations.<br/><br/>3. Disclosure to third parties the Company may provide,
          disclose and share information including Personal Information that is
          collected to third-party service providers, database service
          providers, backup and disaster recovery service providers, email
          service providers and also make it accessible to technology partners,
          subsidiaries and affiliates, including outside India, to help ensure
          availability of Marker Lite and providing the Products and Services<br/><br/>4.
          Disclosure to prospective Acquirers The Company may disclose
          information including Personal Information to an acquirer, assignee or
          other successor entity in connection with a sale, merger, or
          reorganization of all or substantially all of the equity or business
          of the Company.<br/>
          <h2 className="mb-3 pt-5 text-2xl font-semibold  text-gray-900 border-bottom pb-2">
          Protection of information
          </h2>
           The Company, on a
          best-effort basis, shall attempt to take adequate measures to protect
          the security of Personal Information and to ensure that the choices
          for its intended use are honoured. The Company also takes adequate
          precautions, on a best effort basis, to protect data from loss,
          misuse, unauthorized access or disclosure, alteration or destruction.<br/><br/>
          The Company uses industry standards of physical, technical and
          administrative security measures to keep Information, on best effort
          basis, secure although is unequivocally understood and agreed that the
          same cannot be foolproof since no security system is impassable and
          the Company does not guarantee that the Information may be absolutely
          free from invasion.<br/><br/>Please note that e-mails, messaging and means of
          communication with the Company may not be encrypted, and therefore the
          Company advises not to communicate any confidential information
          through these means.<br/><br/>The Company is not responsible for any breach of
          security or for any actions or inactions of any Users or other third
          parties including but not limited to government authorities that
          receive Information.<br/><br/>The User is solely responsible for maintaining
          the confidentiality of its username and password. To ensure the safety
          of Personal Information, it is advised that the username and password
          should not be shared with anyone. The Company would not be liable or
          responsible for any breach of this covenant for any reason.<br/><br/>The
          Company would retain Personal Information only as long as the entity
          or User to whom the said Personal Information belongs has an active
          registration or as permitted/required by applicable law. Generally,
          Personal Information, upon deletion of a related registration, is also
          deleted unless the same is required to comply with any legal
          requirements, fraud prevention or assistance in any inquiry, for
          resolving disputes, to protect the interests of the Company, to
          continue to develop and improve the Products and Services Grievance
          redressal mechanism.<br/><br/>Please describe the information you wish to erase.
          Please provide any relevant details you think will help us to identify
          the information. Providing the URL for each link you wish to be
          removed would be helpful.<br/><br/>Also, please explain, if it is not
          abundantly clear, why the linked page is about you or the person you
          are representing on this form.<br/><br/>Please note that. In certain
          circumstances, where erasure would adversely affect the freedom of
          expression, contradict legal obligation, act against the public
          interest in the area of public health, act against the public interest
          in the area of scientific or historical research, or prohibit the
          establishment of a legal defense or exercise of other claims, we may
          not be able to erase the information you requested in accordance with
          article 17(3) of the GDPR. In such cases you would be informed
          promptly and given full reasons for that decision.<br/><br/>While in most cases
          we will be happy to erase the personal data you request, we
          nevertheless reserve the right, in accordance with article 12(5) of
          the GDPR to charge a fee or refuse the request if it is considered to
          be “manifestly unfounded or excessive.” However, we will make every
          effort to provide you with the erasure of your personal data if
          suitable.<br/><br/>The Company retains Personal Information only as long as
          required by law or an active registration. Generally, upon deletion of
          a registration, Personal Information is also deleted, unless required
          for legal compliance, fraud prevention, inquiry assistance, dispute
          resolution, Company interests, or service development.<br/><br/>By using or
          accessing Marker Lite in any manner or registering, you acknowledge
          acceptance of this Policy and consent to the Company for collection,
          use, disclosure, sharing, and transfer of information and personal
          data, including sensitive Personal Information. This Policy
          acknowledges all software operations approved by the registered person
          and their liability and responsibility for data accuracy, forming an
          electronic contract under the Information Technology Act, 2000, and
          the rules made thereunder.<br/>
          
          <h2 className="mb-3 pt-5 text-2xl font-semibold  text-gray-900 ">
          END OF TERMS OF SERVICE
          </h2>
           If you have any
          questions or concerns regarding this Agreement, please contact us at &nbsp;
          <a
              href="mailto:support.markerlite@alittech.com"
              className="sm:text-xl font-medium text-blue-600 hover:text-blue-800 hover:underline"
            >
              support.markerlite@alittech.com
            </a>
        </div>
      </section>
    </>
  );
};

export default TermsAndConditions;
