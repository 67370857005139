import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { WindowsIcon } from '../Utils/base64icons';

const Header = (props) => {

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const navigateTo = useNavigate();
    
    const toggleMobileMenu = () => {
      setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    
    const CurrentPageLocation = useLocation();


    const ScrollToHome = () => {
        if (CurrentPageLocation.pathname !== "/") {
          navigateTo("/");
          setTimeout(() => {
            const element = document.getElementById("home-section");
            if (element) {
              element.scrollIntoView({ behavior: "smooth" });
            }
          }, 100);
        } else {
          const element = document.getElementById("home-section");
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
        }
        props.setActiveSection("home-section");
      };
    
      const ScrollToFeatures = () => {
        if (CurrentPageLocation.pathname !== "/") {
          navigateTo("/");
          setTimeout(() => {
            const element = document.getElementById("feature-section");
            if (element) {
              element.scrollIntoView({ behavior: "smooth" });
            }
          }, 100);
        } else {
          const element = document.getElementById("feature-section");
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
        }
        props.setActiveSection("feature-section");
      };
    
      const ScrollToBenefits = () => {
        if (CurrentPageLocation.pathname !== "/") {
          navigateTo("/");
          setTimeout(() => {
            const element = document.getElementById("benefit-section");
            if (element) {
              element.scrollIntoView({ behavior: "smooth" });
            }
          }, 100);
        } else {
          const element = document.getElementById("benefit-section");
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
        }
    
        props.setActiveSection("benefit-section");
      };
    
      const ScrollToOurmisssion = () => {
        if (CurrentPageLocation.pathname !== "/") {
          navigateTo("/");
          setTimeout(() => {
            const element = document.getElementById("ourmission-section");
            if (element) {
              element.scrollIntoView({ behavior: "smooth" });
            }
          }, 100);
        } else {
          const element = document.getElementById("ourmission-section");
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
        }
    
        props.setActiveSection("ourmission-section");
      };
    
      const ScrollToPricing = () => {
        if (CurrentPageLocation.pathname !== "/") {
          navigateTo("/");
          setTimeout(() => {
            const element = document.getElementById("pricing-section");
            if (element) {
              element.scrollIntoView({ behavior: "smooth" });
            }
          }, 100);
        } else {
          const element = document.getElementById("pricing-section");
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
        }
    
        props.setActiveSection("pricing-section");
      };

      const ScrollToContactUs = () => {
        if (CurrentPageLocation.pathname !== "/") {
          navigateTo("/");
          setTimeout(() => {
            const element = document.getElementById("contactus-section");
            if (element) {
              element.scrollIntoView({ behavior: "smooth" });
            }
          }, 100);
        } else {
          const element = document.getElementById("contactus-section");
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
        }
    
        props.setActiveSection("contactus-section");
      };
    
      const ScrollToFAQ = () => {
        if (CurrentPageLocation.pathname !== "/") {
          navigateTo("/");
          setTimeout(() => {
            const element = document.getElementById("faq-section");
            if (element) {
              element.scrollIntoView({ behavior: "smooth" });
            }
          }, 100);
        } else {
          const element = document.getElementById("faq-section");
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
        }
    
        props.setActiveSection("faq-section");
      };

      const onClickDownloadTop = () => {
        navigateTo("/download");
        props.setActiveSection("");
        window.scrollTo({top: 0, left: 0, behavior: 'instant'});
      };

  return (
    <>
        <header className={`fixed w-full ${CurrentPageLocation.pathname ===  "/TermsAndConditions" ? 'header-shadow' :''}`}>
        <nav className="bg-white border-gray-200 py-2.5 ">
          <div className="flex flex-wrap items-center justify-between max-w-screen-xl px-4 mx-auto">
            <a href="/" className="flex items-center">
              <img
                src="MarkerLiteLogo.png"
                className="me-2"
                alt="Marker Lite Logo"
                width={180}
              />
              {/* <div className="self-center text-xl font-semibold whitespace-nowrap align-items-center d-flex ">
                <div>Marker Lite</div>
              </div> */}
            </a>
            <div className="flex items-center lg:order-2">
              {CurrentPageLocation.pathname !== "/download" && (
                <button
                  onClick={onClickDownloadTop}
                  className="d-xxl-block d-xl-block d-lg-block d-md-block d-sm-block d-none text-white accent-bg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-semibold rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 lg:mr-0 focus:outline-none"
                >
                  <div className='d-flex'>
                  Download for Free
                  <img
                  src={WindowsIcon}
                  alt="download"
                  width={28}
                  className="ps-2"
                />
                  </div>
                  
                </button>
              )}

              <button
                data-collapse-toggle="mobile-menu-2"
                type="button"
                className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 "
                aria-controls="mobile-menu-2"
                aria-expanded="false"
                onClick={toggleMobileMenu}
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <svg
                  className="hidden w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
            <div
              className={`items-center justify-between ${
                isMobileMenuOpen ? "" : "hidden"
              } w-full lg:flex lg:w-auto lg:order-1`}
              id="mobile-menu-2"
            >
              <ul className="flex flex-col  font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                <li>
                  <button
                    onClick={ScrollToHome}
                    className={`block py-2 pl-3 pr-4 rounded lg:p-0 border-b border-gray-100 lg:border-0 ${
                      props.activeSection === "home-section" &&
                      "accent-text font-semibold"
                    }`}
                  >
                    Home
                  </button>
                </li>
                <li>
                  <button
                    onClick={ScrollToFeatures}
                    className={`block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0  lg:p-0 hover:text-blue-800 ${
                        props.activeSection === "feature-section" &&
                      "accent-text font-semibold"
                    }`}
                  >
                    Features
                  </button>
                </li>
                <li>
                  <button
                    onClick={ScrollToBenefits}
                    className={`block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0  lg:p-0 hover:text-blue-800 ${
                        props.activeSection === "benefit-section" &&
                      "accent-text font-semibold"
                    }`}
                  >
                    Benefits
                  </button>
                </li>
                <li>
                  <button
                    onClick={ScrollToOurmisssion}
                    className={`block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0  lg:p-0 hover:text-blue-800 ${
                        props.activeSection === "ourmission-section" &&
                      "accent-text font-semibold"
                    }`}
                  >
                    Our Mission
                  </button>
                </li>
                <li>
                  <button
                    onClick={ScrollToPricing}
                    className={`block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0  lg:p-0 hover:text-blue-800 ${
                        props.activeSection === "pricing-section" &&
                      "accent-text font-semibold"
                    }`}
                  >
                    Pricing
                  </button>
                </li>
                <li>
                  <button
                    onClick={ScrollToContactUs}
                    className={`block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 hover:text-blue-800 lg:p-0 ${
                        props.activeSection === "contactus-section" &&
                      "accent-text font-semibold"
                    }`}
                  >
                    Contact Us
                  </button>
                </li>
                <li>
                  <button
                    onClick={ScrollToFAQ}
                    className={`block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 hover:text-blue-800 lg:p-0 ${
                        props.activeSection === "faq-section" &&
                      "accent-text font-semibold"
                    }`}
                  >
                    FAQs
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  )
}

export default Header