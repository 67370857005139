import React from 'react';

const ProductSchema = () => {
  return (
    <script type="application/ld+json">
      {`
        {
          "@context": "https://schema.org/", 
          "@type": "Product", 
          "name": "Marker Lite",
          "image": "https://markerlite.alittech.com/static/media/Marker-03.72cc419b1c9c4bc5cb30.png",
          "description": "Free Billing Software For Small Businesses Empower your business with Marker Lite: comprehensive accounting, billing, and Free inventory management software. Take control and reach new heights!",
          "brand": {
            "@type": "Brand",
            "name": "Markerlite"
          }
        }
      `}
    </script>
  );
};

export default ProductSchema;