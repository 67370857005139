export const accordionData = [
    {
      question: "What is billing software?",
      answer: (
        <>
          <p className="mb-2 text-gray-500">
          Professional invoicing and billing software are used to create personalized GST/non-GST bills. It is used to provide better-looking invoices to customers by businesses. These invoices help reflect the brand’s identity. Further, it caters the brand’s value to clients.<br/>
          </p>
          <p className="mb-2 text-gray-500">You can stand out among competitors using Marker Lite billing software. It comes with fully customizable and appealing invoice print designs. Further, you can track down paid and unpaid invoices. You can send payment reminders to the customers too.</p>
          
        </>
      ),
    },
    {
      question: "Which software is best for billing?",
      answer: (
        <>
          <p className="mb-2 text-gray-500">
          Marker Lite free billing software provides all the features required in billing software. Marker Lite is best suited for small and medium businesses. They can easily rely upon their business requirements.
          </p>
          
        </>
      ),
    },
    {
      question: "Which software is best for GST/non GST billing?",
      answer: (
        <>
          <p className="mb-2 text-gray-500">
          GST bills are required for businesses that sell goods and services. It comes under the Goods and Service Tax law. You can use the Marker Lite free billing software to comply with the latest GST laws.
          </p>
          <p className="mb-2 text-gray-500">
            Using the best billing software by Marker Lite, you can create GST bills and Non-GST bills both. If your business is not under GST then you can also create the bills with GST.
          </p>
        </>
      ),
    },
    {
      question: "What is Marker Lite free billing software?",
      answer: (
        <>
          <p className="mb-2 text-gray-500">
          Marker Lite is a Desktop Software, that will be used for GST billing and managing Inventory and accountancy.
          </p>
        </>
      ),
    },
    {
      question: "Is Marker Lite software paid or It's free?",
      answer: (
        <>
          <p className="mb-2 text-gray-500">
          No, It's Absolutely FREE and you can get professional billing by Marker Lite.
          </p>
        </>
      ),
    },
    {
      question: "Will there be any restrictions after a month or after creating some bills?",
      answer: (
        <>
          <p className="mb-2 text-gray-500">
          No, there will be not any restrictions. You can be used without hesitation lifetime.
          </p>
        </>
      ),
    },
    {
      question: "Why Marker Lite is free for a lifetime?",
      answer: (
        <>
          <p className="mb-2 text-gray-500">
          We developed Maker Lite free billing software for MSMEs and took the initiative to align with the "ATMA NIRBHAR BHARAT" and help them become self-sustainable. By leveraging software benefits, MSMEs can become more competitive, resilient, and innovative. We aim to create an empowering environment for MSMEs to prosper and significantly contribute to the nation's growth.
          </p>
        </>
      ),
    },
    {
      question: "If it is free then how to download it?",
      answer: (
        <>
          <p className="mb-2 text-gray-500">
          You can download the setup from <a href="/" className="text-blue-600 hover:text-blue-800 hover:underline">www.markerlite.alittech.com</a>.
          </p>
        </>
      ),
    },
    {
      question: "What is offline billing software?",
      answer: (
        <>
          <p className="mb-2 text-gray-500">
          Offline billing refers to creating billing using offline billing software. It doesn’t have the requirement of an active internet connection. Using offline billing features is crucial for most business owners in India.
          </p>
          <p className="mb-2 text-gray-500">
          This is due to the lack of connectivity in rural areas. Many businesses can’t access the internet during load shedding. Long hours of a power cut make it impossible to bill without offline access. So they must have the best invoicing software for windows like Marker Lite.
          Using the Maker Lite free billing software makes it easier for them to create GST invoices without internet access. The customers don’t have to wait due to internet connectivity issues. Marker Lite ensures that the data is safe by helping businesses with data backups.
          </p>
        </>
      ),
    },
    {
      question: "Which is the best free billing software for PC?",
      answer: (
        <>
          <p className="mb-2 text-gray-500">
          The billing software by Marker Lite is the best software. It is best suited to manage billing requirements for a business. The best part is that free trial with no restrictions.
          </p>
          <p className="mb-2 text-gray-500">
          So, It will ensure that the Marker Lite is useful for their business. All of it makes Marker Lite the best choice among free billing software available.
          </p>
        </> 
      ),
    },
  ];