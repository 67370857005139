/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import heroImg from "../Images/theme-hero.png";

import { accordionData } from "./accordionData";
import mission from "../Images/OurMission/mission.png";
import {
  BarcodeFeature,
  FreeIcon,
  ImportFeature,
  InventoryFeature,
  InvoicesFeature,
  MultipleCompanyFeature,
  WindowsIcon,
  accountingFeature,
  accountingIcon,
  backupIcon,
  buildTrustIcon,
  customFieldFeature,
  customerWisePriceFeature,
  gstIcon,
  inventoryIcon,
  multiplePriceListFeature,
  multiusersIcon,
  offline,
  offlineBilllingIcon,
  securityIcon,
  speedIcon,
  trackIcon,
} from "../Utils/base64icons";
import GSTImage from "../Images/GST-blue-01.png";
import QR from "../Images/scanner.png";
import thermalPrinter from "../Images/printer03.png";
import paymentRecovery from "../Images/payment-recovery-01.png";
import makeInIndia from "../Images/OurMission/makeinindia.png";
import digitalIndia from "../Images/OurMission/digitalIndia.png";
import ANBA from "../Images/OurMission/ANBA.png";
import MSME from "../Images/OurMission/msme.png";
import GSTlogo from "../Images/OurMission/gstlogo.png";
import { useNavigate } from "react-router-dom";

const Home = (props) => {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  const sectionIds = [
    "home-section",
    "feature-section",
    "benefit-section",
    "ourmission-section",
    "pricing-section",
    "contactus-section",
    "faq-section",
  ];
  const sectionRefs = useRef(sectionIds.map(() => React.createRef()));

  function handleScroll() {
    for (let i = 0; i < sectionIds.length; i++) {
      const sectionRef = sectionRefs.current[i];
      if (sectionRef && sectionRef.current) {
        const rect = sectionRef.current.getBoundingClientRect();
        if (
          rect.top <= window.innerHeight / 2 &&
          rect.bottom >= window.innerHeight / 2
        ) {
          props.setActiveSection(sectionIds[i]);
          return;
        }
      }
    }
  }
  const navigateTo = useNavigate();

  const onClickDownload = () => {
    props.setActiveSection("");
    navigateTo("/download");
    window.scrollTo({top: 0, left: 0, behavior: 'instant'});
  };

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);



  return (
    <div>
      <section
        className="bg-white  py-5"
        id={sectionIds[0]}
        ref={sectionRefs.current[0]}
      >
        <div className="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-28">
          <div className="mr-auto place-self-center lg:col-span-7">
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold leading-none tracking-tight md:text-5xl xl:text-6xl ">
              Free Billing Software For Small Businesses.
            </h1>
            <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl ">
              Manage your business easily with fully-featured billing,
              accounting, and inventory management software. Transform your billing processes with our intuitive software to save time and enhance productivity. Get control of your
              business at your fingertips and reach new heights with intuitive
              MARKER Lite.
            </p>
            <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
              <button
                onClick={onClickDownload}
                className="inline-flex justify-center mb-1 items-center text-white accent-bg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300   w-full download-hero-btn py-3 text-md font-semibold text-center  rounded-lg sm:w-auto"
              >
                Download for Free
                <img
                  src={WindowsIcon}
                  alt="download"
                  className="ps-2"
                />
              </button>
            </div>
            <span className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-md lg:text-md ">
              {" "}
              *Absolutely free- No hidden charges
            </span>
          </div>
          <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
            <img src={heroImg} alt="hero " width={400} />
          </div>
        </div>
      </section>

      <section className="bg-white pb-3">
        <div className="max-w-screen-xl px-4 pb-8 mx-auto lg:pb-16">
          <div className="grid grid-cols-2 gap-4 text-gray-500 sm:gap-12 sm:grid-cols-3 lg:grid-cols-6 ">
            <div>
              <img src={speedIcon} alt="fastest" width={64} className="mb-2" />
              <h6 className=" text-lg font-semibold">Fastest</h6>
              Billing software
            </div>

            <div>
              <img src={offline} alt="Offline" width={50} className="my-2" />
              <h6 className=" text-lg font-semibold">Offline</h6>
              No internet required
            </div>
            <div>
              <img
                src={multiusersIcon}
                alt="multiusers"
                width={60}
                className="mb-2"
              />
              <h6 className=" text-lg font-semibold">Multi User</h6>
              Connect with the team
            </div>
            <div>
              <img
                src={accountingIcon}
                alt="accounting"
                width={60}
                className="mb-2"
              />
              <h6 className=" text-lg font-semibold">Accounting</h6>
              Beyond balance sheet
            </div>
            <div>
              <img
                src={inventoryIcon}
                alt="inventory"
                width={60}
                className="mb-2"
              />
              <h6 className=" text-lg font-semibold">Inventory</h6>
              Simplified & optimized
            </div>
            <div>
              <img
                src={gstIcon}
                alt="gst/non-gst"
                width={60}
                className="mb-2"
              />
              <h6 className=" text-lg font-semibold">GST</h6>
              Easy compliance
            </div>
          </div>
        </div>
      </section>
      <section className="bg-gray-50 pt-5">
        <div className="max-w-screen-xl px-4 pt-8 mx-auto ">
          <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
            <div className="text-gray-500 sm:text-lg ">
              <h2 className="mb-4 sub-title font-extrabold tracking-tight text-gray-900 ">
                GST and Non GST Invoicing
              </h2>
              <p className="mb-8 font-light lg:text-xl">
                Marker Lite free billing software with GST easily creates and
                prints GST & non-GST invoices in just a few clicks. The
                user-friendly software ensures accurate tax calculations and
                incorporates them into invoices effortlessly. It is fully customizable software 
                that allows users to enable VAT along with/without GST. Marker Lite is
                designed for micro, small, and medium-sized businesses and can
                be customised to handle GST/non-GST invoices.
              </p>
            </div>
            <div className="d-flex justify-content-center">
              <img
                className="hidden  mb-4 rounded-lg lg:mb-0 lg:flex"
                src={GSTImage}
                alt="dashboard feature"
              />
            </div>
          </div>
          {/* <!-- Row --> */}
          <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
            <img
              className="hidden w-full mb-4 rounded-lg lg:mb-0 lg:flex"
              src={QR}
              alt="feature 2"
            />
            <div className="text-gray-500 sm:text-lg ">
              <h2 className="mb-4 sub-title font-extrabold tracking-tight text-gray-900 ">
                UPI QR Code on Invoices
              </h2>
              <p className="mb-8 font-light lg:text-xl">
                The customer-friendly billing software with GST enables
                customers to make payments using UPI by scanning the QR code
                printed on the invoices. The dynamic QR code feature promotes
                digital payment results in quick and error-free transactions. 
                The Invoicing software optimizes the billing printing, enhances user experience, and keeps business tech-savvy.
                The advanced feature of Marker Lite equips businesses with safe,
                transparent, instant, and convenient payment solutions that
                build positive goodwill and trust.
              </p>
            </div>
          </div>

          <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
            <div className="text-gray-500 sm:text-lg ">
              <h2 className="mb-4 sub-title font-extrabold tracking-tight text-gray-900 ">
                Regular/Thermal Printer
              </h2>
              <p className="mb-8 font-light lg:text-xl">
                Marker Lite free billing software with GST is compatible with
                thermal printing and regular printing options. The intuitive
                feature of the software provides 25+ billing templates you can
                select per your business needs. The software ensures fast
                billing with high-quality invoice printing on regular A4, A5,
                and A6 paper sizes, and it also supports thermal printers with
                3-inch to 4-inch printing capabilities.  The invoice printing software supports all major brands like TVS along with new brands. 
              </p>
            </div>
            <img
              className="hidden w-full mb-4 rounded-lg lg:mb-0 lg:flex p-4"
              src={thermalPrinter}
              alt="printer"
            />
          </div>

          <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
            <img
              className="hidden w-full mb-4 rounded-lg lg:mb-0 lg:flex"
              src={paymentRecovery}
              alt="payment"
            />
            <div className="text-gray-500 sm:text-lg ">
              <h2 className="mb-4 sub-title font-extrabold tracking-tight text-gray-900 ">
                Faster Payment Recovery
              </h2>
              <p className=" font-light lg:text-xl">
                The robust billing software with GST indicates the customers'
                outstanding overdue invoices and helps to notify the customers
                of due payment. The cutting-edge feature helps micro and small
                businesses to ensure timely payments and maintain cash flow. The
                incorporated feature reduces the risk of bad debts and enables
                better financial planning for a healthy cash flow. Our customers reported they have 40% faster payment recovery 
                with Marker Lite free billing software compared to conventional methods.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        className="bg-gray-50 pt-20 pb-5"
        id={sectionIds[1]}
        ref={sectionRefs.current[1]}
      >
        <div className="max-w-screen-xl px-4  mx-auto  pb-4  lg:px-6">
          <div className="items-center ">
            <div className="text-gray-500 sm:text-lg ">
              <h2 className=" text-3xl font-extrabold tracking-tight text-gray-900 ">
                Features
              </h2>
              <h3 className="pt-4 mb-2 text-xl font-semibold tracking-tight text-gray-900">
                Loaded with Features For Managing Business Easily
              </h3>
              <p className="font-light text-gray-500 sm:text-xl  border-b pb-2 border-gray-200 ">
              Marker Lite is a free billing software to transform micro and
              small enterprises into fully integrated business solutions. 
              We aim to be the first free billing software to include millions
              of SMEs in the digital economy to improve their growth potential.
              The billing software offers a range of features that make invoicing,
              financial tracking, and reporting more accessible than ever before.
              The powerful functionalities of our billing software businesses can improve cash 
              flow, enhance client satisfaction, and optimize their billing operations.
              </p>
            </div>
          </div>
        </div>

        <div className="items-center max-w-screen-xl px-4 pb-5 mx-auto  lg:gap-16 xl:gap-24  lg:px-6">
          <div className="row ">
            <div className="col-xxl col-xl col-lg col-md  ">
              <div className="mt-5">
                <img
                  src={accountingFeature}
                  alt="accountingFeature"
                  width={68}
                  className="pb-2"
                />
                <h3 className="mb-2 text-xl font-semibold">Accounting</h3>
                <p className="font-light text-gray-500 sm:text-lg">
                  Simplified accounting features enable you to manage books 
                  without the help of an accountant's assistant. The accounting 
                  software helps MSMEs to record transactions and generate quick, 
                  simple financial reports.
                </p>
              </div>
            </div>
            <div className="col-xxl col-xl col-lg col-md">
              <div className="mt-5">
                <img
                  src={InventoryFeature}
                  alt="InventoryFeature"
                  width={68}
                  className="pb-2"
                />
                <h3 className="mb-2 text-xl font-semibold">Inventory</h3>
                <p className="font-light text-gray-500 sm:text-lg">
                The inventory management software helps micro and small 
                enterprises manage their stocks in a few clicks. Marker Lite
                free billing software helps you to track stocks at your fingertips by generating inventory 
                reports to provide better control and transparency.
                </p>
              </div>
            </div>
            <div className="col-xxl col-xl col-lg col-md">
              {" "}
              <div className="mt-5">
                <img
                  src={customFieldFeature}
                  alt="customFieldFeature"
                  width={68}
                  className="pb-2"
                />
                <h3 className="mb-2 text-xl font-semibold">Custom Field</h3>
                <p className="font-light text-gray-500 sm:text-lg">
                The custom field feature in billing software lets users add their personalized details to invoices. 
                This flexibility helps businesses meet specific needs and make better-informed decisions.
                </p>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-xxl col-xl col-lg col-md ">
              <div className="mt-5">
                <img
                  src={MultipleCompanyFeature}
                  alt="MultipleCompanyFeature"
                  width={68}
                  className="pb-2"
                />
                <h3 className="mb-2 text-xl font-semibold">Multi-Company</h3>
                <p className="font-light text-gray-500 sm:text-lg">
                The powerful free billing software with GST allows businesses to manage 
                financial transactions for multiple companies in a single database. 
                The robust feature of Marker Lite provides real-time information to
                 help your businesses become more efficient and profitable.
                </p>
              </div>
            </div>
            <div className="col-xxl col-xl col-lg col-md">
              <div className="mt-5">
                <img
                  src={multiplePriceListFeature}
                  alt="multiplePriceListFeature"
                  width={68}
                  className="pb-2"
                />
                <h3 className="mb-2 text-xl font-semibold">
                  Multiple Price Lists
                </h3>
                <p className="font-light text-gray-500 sm:text-lg">
                 The multiple price lists allow businesses to define
                 different rates for customers, retailers, and wholesalers
                 of their products. Marker Lite's flexible pricing strategy
                 maximizes profits and keeps you ahead of the competition.
                </p>
              </div>
            </div>
            <div className="col-xxl col-xl col-lg col-md">
              {" "}
              <div className="mt-5">
                <img
                  src={customerWisePriceFeature}
                  alt="customerWisePrice"
                  width={68}
                  className="pb-2"
                />
                <h3 className="mb-2 text-xl font-semibold">
                  Customer Wise Rates
                </h3>
                <p className="font-light text-gray-500 sm:text-lg">
                Marker Lite is a free billing software with GST that assigns custom billing rates 
                for individual customers. The robust feature of providing personalized rates 
                reduces error and helps to build Trust and goodwill.
                </p>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-xxl col-xl col-lg col-md ">
              <div className="mt-5">
                <img
                  src={BarcodeFeature}
                  alt="free"
                  width={68}
                  className="pb-2"
                />
                <h3 className="mb-2 text-xl font-semibold">Barcode</h3>
                <p className="font-light text-gray-500 sm:text-lg">
                  Marker Lite is free barcode billing software with GST, which makes billing quick and easy.
                  The dynamic barcode integration tracks inventory accurately and improves the efficiency of micro and small businesses.
                </p>
              </div>
            </div>
            <div className="col-xxl col-xl col-lg col-md">
              <div className="mt-5">
                <img
                  src={InvoicesFeature}
                  alt="InvoicesFeature"
                  width={68}
                  className="pb-2"
                />  
                <h3 className="mb-2 text-xl font-semibold">
                  Invoice Formats
                </h3>
                <p className="font-light text-gray-500 sm:text-lg">
                  The intuitive billing software offers over 25 free billing invoice 
                  formats customized to meet your needs. This customizable template ensures clarity and professionalism, 
                  positively impacting clients and accelerating payment processing.
                </p>
              </div>
            </div>
            <div className="col-xxl col-xl col-lg col-md">
              {" "}
              <div className="mt-5">
                <img
                  src={ImportFeature}
                  alt="ImportFeature"
                  
                  className="pb-2"
                />
                <h3 className="mb-2 text-xl font-semibold">
                Import from Excel{" "}
                </h3>
                <p className="font-light text-gray-500 sm:text-lg">
                Marker Lite allows you to import Item Master, Price List, Accounts, Customers, 
                and Suppliers from an Excel file. The free billing software enables users to export all 
                receipts, invoices, and reports in Excel, Word, PDF, Text, CSV, Image, and HTML formats.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="bg-white "
        id={sectionIds[2]}
        ref={sectionRefs.current[2]}
      >
        <div className="items-center max-w-screen-xl px-4 py-8 mx-auto lg:grid lg:grid-cols-4 lg:gap-16 xl:gap-24 lg:py-24 lg:px-6">
          <div className="col-span-2 mb-8">
            <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 md:text-3xl ">
              Benefits of Using Marker Lite Software
            </h2>
            <p className="font-light text-gray-500 sm:text-xl ">
              Marker Lite is a free billing software with intelligent features
              to streamline your business quickly. The robust software automates
              finance, reduces errors, and saves time and money with accuracy
              and compliance. Free billing software is valuable for companies 
              seeking efficient and budget-friendly invoicing solutions.
               It is ideal for small businesses and startups to manage 
               financial transactions and increase productivity.
              <br /> <br/>
              Let's discuss the benefits of using MARKER LITE software to drive
              your business smoothly.
            </p>
            <div className="pt-6 mt-6  border-t border-gray-200 ">
            </div>
          </div>
          <div className="col-span-2 space-y-8 md:grid md:grid-cols-2 md:gap-12 md:space-y-0">
            <div>
              <img src={FreeIcon} alt="free" width={60} className="pb-2" />
              <h3 className="mb-2 text-xl font-bold ">
                Lifetime Free, No Hidden Charges
              </h3>
              <p className="font-light text-gray-500 ">
              The billing software gives you lifetime free usage to create unlimited invoices
               for multiple companies while supporting multi-user functionality.
              </p>
            </div>
            <div>
              <img src={trackIcon} alt="track" width={60} className="pb-2" />
              <h3 className="mb-2 text-xl font-bold ">
                Real-time Business Tracking
              </h3>
              <p className="font-light text-gray-500 ">
                Integrated real-time tracking promotes transparency, enhances
                client satisfaction, and builds trust.
              </p>
            </div>
            <div>
              <img
                src={offlineBilllingIcon}
                alt="billing"
                width={60}
                className="pb-2"
              />
              <h3 className="mb-2 text-xl font-bold ">Offline Billing</h3>
              <p className="font-light text-gray-500 ">
                {" "}
                Works offline to provide the highest uptime faster speed, and
                ensures better data security.
              </p>
            </div>
            <div>
              <img src={backupIcon} alt="backup" width={60} className="pb-2" />
              <h3 className="mb-2 text-xl font-bold ">Safe Backup</h3>
              <p className="font-light text-gray-500 ">
                Prevents data loss with secure backup and restore features and
                maintains data integrity.
              </p>
            </div>
            <div>
              <img
                src={securityIcon}
                alt="security"
                width={60}
                className="pb-2"
              />
              <h3 className="mb-2 text-xl font-bold ">Security</h3>
              <p className="font-light text-gray-500 ">
                Dynamic protection keeps your private data safe and secure from
                unauthorised access.
              </p>
            </div>
            <div>
              <img
                src={buildTrustIcon}
                alt="trust"
                width={60}
                className="pb-2"
              />
              <h3 className="mb-2 text-xl font-bold ">Build Trust</h3>
              <p className="font-light text-gray-500 ">
                Printing high-quality and Error-free invoices with correct GST &
                tax compliance builds trust among the customers and suppliers.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section
        className="bg-gray-50 "
        id={sectionIds[3]}
        ref={sectionRefs.current[3]}
      >
        <div className="max-w-screen-xl px-4 py-8 mx-auto space-y-12 lg:space-y-20 lg:py-24 lg:px-6">
          {/* <!-- Row --> */}
          <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
            <div className="text-gray-500 sm:text-lg ">
              <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 ">
                Our Mission
              </h2>
              <p className="mb-8 font-light lg:text-xl">
              We aim to help small and medium businesses switch from manual to digital methods. 
              We made a free billing software that's affordable for these businesses. 
              It helps them manage billing, accounting, inventory, and GST easily. 
              The software is user-friendly and supports the idea of self-reliance.
              We created this free software for small and medium businesses and aligned
              it with "ATMA NIRBHAR BHARAT" to promote self-reliance and economic independence in India.
                <br/><br/>
              Making better billing solutions also helps our industries grow, 
              supports local businesses, and reduces our dependence on foreign technology.
              The user-friendly software is accessible to companies of all sizes and 
              technical levels. This software allows small and medium enterprises to
              become more competitive, resilient, and innovative. We aim to create an
              empowering environment for MSMEs to succeed and contribute meaningfully to the nation's growth.

              </p>
              
            </div>
            <img
              className="hidden w-full mb-4 rounded-lg lg:mb-0 lg:flex"
              src={mission}
              alt="dashboard feature"
            />
          </div>
          {/*  Row */}
          <div className="max-w-screen-xl px-4  mx-auto ">
            <div className="grid grid-cols-2 gap-5 text-gray-500 lg:grid-cols-5 ">
              <div>
                <img
                  src={digitalIndia}
                  alt="digitalindia"
                  width={190}
                  className=""
                />
              </div>

              <div>
                <img src={makeInIndia} alt="makeInIndia" width={160} />
              </div>
              <div>
                <img
                  src={ANBA}
                  alt="multiusers"
                  width={160}
                  className="my-xxl-3 my-xl-3 my-lg-3 my-2"
                />
              </div>
              <div>
                <img
                  src={MSME}
                  alt="accounting"
                  width={160}
                  className=" my-2"
                />
              </div>
              <div>
                <img
                  src={GSTlogo}
                  alt="accounting"
                  width={65}
                  className="mb-2"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="bg-white "
        id={sectionIds[4]}
        ref={sectionRefs.current[4]}
      >
        <div className="max-w-screen-xl px-4 pt-5 mt-2  mx-auto lg:pt-24 lg:px-6">
          <div className="max-w-screen-md mx-auto mb-8 text-center lg:mb-12">
            <h2 className="mb-4 pt-5 text-3xl font-extrabold tracking-tight text-gray-900 ">
            Free billing software with plenty of benefits
            </h2>
          </div>
          {/* <!-- Pricing Card --> */}
          <div className="flex  flex-col max-w-lg p-6 mx-auto text-center text-gray-900 bg-white border border-gray-100 rounded-lg shadow mb-4">
            
            <h3 className="mb-4 text-2xl font-bold">Pricing</h3>
            <p className="font-light text-gray-500 sm:text-lg">
            Lifetime free, no hidden charges.<br/>No credit or debit card is required.

            </p>
            <div className="flex items-baseline justify-center my-8">
              <span className="mr-2 text-5xl font-extrabold">&#x20B9;0</span>
            </div>
            {/* <!-- List --> */}
            <ul className="mb-8 space-y-4 text-left">
              <li className="flex items-center space-x-3">
                {/* <!-- Icon --> */}
                <svg
                  className="flex-shrink-0 w-5 h-5 text-green-500 "
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span>Unlimited Invoices</span>
              </li>
              <li className="flex items-center space-x-3">
                {/* <!-- Icon --> */}
                <svg
                  className="flex-shrink-0 w-5 h-5 text-green-500 "
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span>Unlimited Companies</span>
              </li>
              <li className="flex items-center space-x-3">
                {/* <!-- Icon --> */}
                <svg
                  className="flex-shrink-0 w-5 h-5 text-green-500 "
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span>Unlimited Users</span>
              </li>
              <li className="flex items-center space-x-3">
                {/* <!-- Icon --> */}
                <svg
                  className="flex-shrink-0 w-5 h-5 text-green-500 "
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span>Unlimited Price lists</span>
              </li>
              <li className="flex items-center space-x-3">
                {/* <!-- Icon --> */}
                <svg
                  className="flex-shrink-0 w-5 h-5 text-green-500 "
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span>Customer-specific price list</span>
              </li>
              <li className="flex items-center space-x-3">
                {/* <!-- Icon --> */}
                <svg
                  className="flex-shrink-0 w-5 h-5 text-green-500 "
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span>25+ free professional invoice templates</span>
              </li>
              <li className="flex items-center space-x-3">
                {/* <!-- Icon --> */}
                <svg
                  className="flex-shrink-0 w-5 h-5 text-green-500 "
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span>Accounts and Inventory control</span>
              </li>
              <li className="flex items-center space-x-3">
                {/* <!-- Icon --> */}
                <svg
                  className="flex-shrink-0 w-5 h-5 text-green-500 "
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span>GST Compliance</span>
              </li>
              <li className="flex items-center space-x-3">
                {/* <!-- Icon --> */}
                <svg
                  className="flex-shrink-0 w-5 h-5 text-green-500 "
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span>Import and export data in various formats</span>
              </li>
            </ul>
            <button
              onClick={onClickDownload}
              className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-semibold rounded-lg text-sm px-5 py-2.5 text-center"
            >
              <div className="d-flex justify-content-center">
              Download Now
              <img
                  src={WindowsIcon}
                  alt="download"
                  width={28}
                  className="ps-2"
                  />
                  </div>
            </button>
          </div>
        </div>
      </section>

      <section
        className="bg-white "
        id={sectionIds[5]}
        ref={sectionRefs.current[5]}
      >
        <div className="max-w-screen-xl px-4 pt-xxl-4 pt-xl-4 pt-lg-4 pt-md-4 pt-0 mt-3 pb-8 mx-auto  lg:px-6">
          <div className="max-w-screen-md mx-auto mb-4 text-center lg:mb-12 pt-5">
            <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 ">
              Contact Us
            </h2>
            <p className="mb-3 font-light text-gray-500 sm:text-xl ">
            Have questions or need assistance with our billing software? We're here to help. Reach out to our support team via
            </p>
            <span className=" font-light text-gray-500  sm:text-xl">Email:</span>{" "}
            <a
              href="mailto:support.markerlite@alittech.com"
              className="sm:text-xl font-medium text-blue-600 hover:text-blue-800 hover:underline"
            >
              support.markerlite@alittech.com
            </a>
            <div className="border-t mt-3 pt-3 border-gray-200">
              {/* <h3 className="text-2xl font-semibold">Drop your message!</h3> */}
            </div>
          </div>
          {/* <div className="d-flex justify-content-center ">
            <div>
              <label htmlFor="email">Email:</label>
              <br />
              <input type="text" id="email" name="email" />
            </div>
          </div>
          <div className="d-flex justify-content-center pt-3">
            <div>
              <label htmlFor="companyname">Company/Organization Name:</label>
              <br />
              <input type="text" id="companyname" name="companyname" />
            </div>
          </div>
          <div className="d-flex justify-content-center pt-3">
            <div>
              <label htmlFor="subject">Subject:</label>
              <br />
              <input type="text" id="subject" name="companyname" />
            </div>
          </div>
          <div className="d-flex justify-content-center pt-3">
            <div>
              <label htmlFor="Message">Message:</label>
              <br />
              <textarea type="text" id="message" name="message" rows="4" />
            </div>
          </div>
          <div className="d-flex justify-content-center pt-4">
            <a
              href="#"
              className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-semibold rounded-lg text-sm px-5 py-2.5 text-center "
              id="submitMessage"
            >
              Submit
            </a>
          </div> */}
        </div>
        <section className="bg-gray-50 ">
        <div className="max-w-screen-xl px-4 py-8 mx-auto lg:py-16 lg:px-6">
          <div className=" mx-auto text-center">
            <h2 className="mb-4 text-3xl font-extrabold leading-tight tracking-tight text-gray-900 ">
              Transform your Business Today for Free with Marker Lite
            </h2>
            <p className=" font-light text-gray-500  md:text-lg">
            Download Marker Lite latest version for free. Marker Lite is compatible with windows 10, and 11.
            </p>
            <p className="mb-6 font-light text-gray-500  md:text-lg">
            No installation cost, no hidden charges, unrestricted access, life time free.
            </p>
            <button
              onClick={onClickDownload}
              className=" text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-semibold rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 focus:outline-none"
            >
              <div className="d-flex">

              Click here to Download <img
                  src={WindowsIcon}
                  alt="download"
                  width={28}
                  className="ps-2"
                  />
                  </div>
            </button>
          </div>
        </div>
      </section>
      </section>    

      {/* FAQ */}
      <section className="bg-white pt-xxl-4 pt-xl-4 pt-lg-4 pt-md-4 pt-0 border-bottom"  id={sectionIds[6]}
        ref={sectionRefs.current[6]}>
        <div className="max-w-screen-xl px-4 pb-8 mx-auto lg:px-6 pt-5">
          <h2 className=" text-3xl font-extrabold tracking-tight text-center text-gray-900  lg:text-3xl ">
            Frequently asked questions (FAQs)
          </h2>

          <div className="px-xxl-4 px-xl-5 px-lg-5 px-md-5 px-0 pt-4 pb-5 mx-xxl-5 mx-xl-5 mx-lg-5 mx-md-5  mx-0 ">
            <div id="accordion-flush" className="px-xxl-5 px-xl-5 px-lg-5 px-md-5 px-0">
              {accordionData.map((item, index) => (
                <React.Fragment key={index}>
                  <h3 id={`accordion-flush-heading-${index}`}>
                    <button
                      type="button"
                      className={`flex items-center justify-between w-full py-4 font-medium text-left ${
                        activeAccordion === index
                          ? "text-gray-900"
                          : "text-gray-500"
                      } bg-white border-b border-gray-200`}
                      onClick={() => toggleAccordion(index)}
                      aria-expanded={
                        activeAccordion === index ? "true" : "false"
                      }
                      aria-controls={`accordion-flush-body-${index}`}
                    >
                      <span>{item.question}</span>
                      <svg
                        data-accordion-icon=""
                        className={`w-6 h-6 ${
                          activeAccordion === index ? "rotate-180" : ""
                        } shrink-0`}
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </h3>
                  <div
                    id={`accordion-flush-body-${index}`}
                    className={
                      activeAccordion === index
                        ? "border-bottom pt-2 pb-4 px-2 bg-light-blue"
                        : "hidden"
                    }
                    aria-labelledby={`accordion-flush-heading-${index}`}
                  >
                    {item.answer}
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End block --> */}
    </div>
  );
};

export default Home;
