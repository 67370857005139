import React from 'react';

const OrganizationSchema = () => {
  return (
    <script type="application/ld+json">
      {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "Alit Technologies",
          "url": "https://markerlite.alittech.com/",
          "logo": "https://markerlite.alittech.com/static/media/Marker-03.72cc419b1c9c4bc5cb30.png"
        }
      `}
    </script>
  );
};

export default OrganizationSchema;