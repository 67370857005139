/* eslint-disable jsx-a11y/anchor-is-valid */
import "bootstrap/dist/css/bootstrap.css";
import Home from "./Component/home";
import "./output.css";
import "./styles.scss";
import {
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import DownloadPage from "./Component/DownloadPage";

import { useEffect, useState } from "react";
import Footer from "./Component/Footer";
import Header from "./Component/Header";
import InvalidPage from "./Component/InvalidPage";
import TermsAndConditions from "./Component/TermsAndConditions";
import { ArrowUpIcon } from "./Utils/base64icons";
import ProductSchema from "./Schema/ProductSchema";
import OrganizationSchema from "./Schema/OrganizationSchema";

function App() {
  const CurrentPageLocation = useLocation();

  const [activeSection, setActiveSection] = useState("");
  const [showTopBtn, setShowTopBtn] = useState(false);

  const ScrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  useEffect(() => {
    if (CurrentPageLocation.pathname !== "/") {
      setActiveSection("");
    } else {
      setActiveSection("home-section");
      const element = document.getElementById("home-section");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [CurrentPageLocation.pathname]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 40) {
        setShowTopBtn(true);
      } else {
        setShowTopBtn(false);
      }
    });
  }, []);

  return (
    <>
      <Header
        setActiveSection={setActiveSection}
        activeSection={activeSection}
      />
      <Routes>
        <Route
          path="/"
          element={<Home setActiveSection={setActiveSection} />}
        />
        <Route path="/download" element={<DownloadPage />} />
        <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
        <Route path="*" element={<InvalidPage />} />
      </Routes>
      <Footer setActiveSection={setActiveSection} />
      <ProductSchema />
      <OrganizationSchema />
      <button
        id="ScrollToTopBtn"
        title="Go to top"
        onClick={ScrollToTop}
        className={`${
          showTopBtn ? "" : "d-none"
        } scroll-to-top-btn font-extrabold accent-bg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300  rounded text-white p-2`}
      >
        <img src={ArrowUpIcon} alt="Arrow Up" width={20} />
      </button>
    </>
  );
}

export default App;
