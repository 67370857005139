import React from "react";
import Img404 from "../Images/404-01.png";
import { useNavigate } from "react-router-dom";

const InvalidPage = () => {
  const navigateTo = useNavigate();

  const GoToHome = () => {
    navigateTo("/");
  };

  return (
    <>
      <div className="invalid-page d-flex justify-center ">
        <div className="text-center pt-2">
          <h3 className="font-bold text-2xl text-blue-600 pt-4">
            Page not found :(
          </h3>
          <p className="mb-4 text-gray-500">we couldn't locate the page you're looking for.</p>
          <img src={Img404} alt="404" width={400} />
          <button
            onClick={GoToHome}
            className="text-center mt-4 text-white accent-bg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-semibold rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 lg:mr-0 focus:outline-none"
          >
            Go To Home
          </button>
        </div>
      </div>
    </>
  );
};

export default InvalidPage;
